import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header /> {/* Hero Start */}
      <div className="container-fluid  w3-banner jarallax" style={{paddingTop:"50px"}}>
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "-1" }}
        >
          <source src="img/video.mp4" type="video/mp4" />
        </video>
      </div>
      {/* Hero End */}
      {/* About Satrt */}
      <div className="container-fluid about py-5">
        <div className="container py-5">
          <div className="row g-5 mb-5">
            <div className="col-xl-6">
              <div className="row g-4" style={{paddingTop:"100px"}}>
                <div className="col-6">
                  <img
                    src="img/Air Freight.jpg"
                    className="img-fluid h-100 wow zoomIn"
                    data-wow-delay="0.1s"
                    alt=""
                  />
                </div>
                <div className="col-6">
                  <img
                    src="img/About1.jpg"
                    className="img-fluid pb-3 wow zoomIn"
                    data-wow-delay="0.1s"
                    alt=""
                  />
                  <img
                    src="img/About2.jpg"
                    className="img-fluid pt-3 wow zoomIn"
                    data-wow-delay="0.1s"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 wow fadeIn" data-wow-delay="0.5s">
              <p className="fs-5 text-uppercase text-primary">
                About {companyname}
              </p>
              <h1 className="display-5 pb-4 m-0">
                Excellence in Every Shipment.
              </h1>
              <p>
                {companyname} has proudly held a prominent position as a
                provider of freight and trading services. Over time, we have
                embraced the challenges that our clients' intricate quandaries
                present, consistently delivering inventive and ingenious
                solutions. Our expertise, skilled workforce, cutting-edge
                technology, and the cumulative experience of our parent company
                come together to form an unbeatable alliance. Our origins are
                rooted in resolving complexities for our own entities, which is
                why we excel in expertly managing the burdens that may weigh
                upon you.
              </p>

              <p>
                Our approach extends beyond mere service provision; it is a
                dynamic partnership, a collaborative endeavor underpinned by
                clear and mutual understanding of your objectives. As we move
                forward, your success becomes intricately linked with ours.
              </p>

              <div className=" g-2">
                <Link to="/About" className="btn btn-primary px-3">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
            {/* Sermon Start */}
            <div className="container-fluid sermon py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "700px" }}
          >
            <p className="fs-5 text-uppercase text-primary">Services</p>
            <h1 className="display-3">Explore Our Services</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Road Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Road" className="d-inline-block h4 lh-sm mb-3">
                    Road Freight
                  </Link>
                  <p className="mb-0">
                    {companyname} takes immense pride in expanding our expertise
                    to include the critical domain of land freight trucking
                    services, in addition to our well-established air and ocean
                    freight offerings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Sea Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Sea" className="d-inline-block h4 lh-sm mb-3">
                    Sea Freight
                  </Link>
                  <p className="mb-0">
                    Whether you're dealing with a comprehensive full container
                    load (FCL) or a more streamlined less than container load
                    (LCL) shipment, you can count on us to align with your
                    overseas shipping schedules.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Air Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Air" className="d-inline-block h4 lh-sm mb-3">
                    Air Freight
                  </Link>
                  <p className="mb-0">
                    {companyname} is your trusted partner for delivering
                    comprehensive door-to-door air freight transportation
                    solutions for imports and exports. Our services encompass
                    various considerations,
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Warehouse.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link
                    to="/Warehouse"
                    className="d-inline-block h4 lh-sm mb-3"
                  >
                    Warehouse
                  </Link>
                  <p className="mb-0">
                    At {companyname}, our wealth of experience, finely
                    cultivated over time, is dedicated to delivering top-tier
                    warehouse services that prioritize the safety and security
                    of your valuable products.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Contract Logistics.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Contract" className="d-inline-block h4 lh-sm mb-3">
                    Contract Logistics
                  </Link>
                  <p className="mb-0">
                    As a pioneering force in the field of contract logistics,{" "}
                    {companyname} stands apart due to its extensive decade-long
                    experience and profound expertise in the industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Third Party Logistics.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Third" className="d-inline-block h4 lh-sm mb-3">
                    Third Party Logistics
                  </Link>
                  <p className="mb-0">
                    {companyname} stands as a leading third-party logistics
                    (3PL) services provider, specializing in delivering
                    comprehensive and seamlessly integrated supply chain
                    solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Sermon End */}
      {/* Activities Start */}
      <div className="container-fluid activities ">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6 col-xl-6">
              <div
                className="activities-item p-4 wow fadeIn"
                data-wow-delay="0.1s"
              >
                <i className="fa fa-bullseye fa-4x text-dark" />
                <div className="ms-4">
                  <h4>Our Mission</h4>
                  <p className="mb-4">
                    Our mission is to rise as trailblazers in the global
                    forwarding and logistics sector, earning worldwide acclaim
                    by consistently exceeding our clients' expectations and
                    catering to their diverse requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div
                className="activities-item p-4 wow fadeIn"
                data-wow-delay="0.3s"
              >
                <i className="fa fa-eye fa-4x text-dark" />
                <div className="ms-4">
                  <h4>Our Vision</h4>
                  <p className="mb-4">
                    To ensure the fulfillment of our clients' needs and to
                    surpass their expectations, we acknowledge the necessity of
                    continual evolution and staying informed about the
                    ever-changing landscape of emerging trends within our
                    industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Activities Start */}

      {/* Team Start */}
      <div className="container-fluid team py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "700px" }}
          >
            <p className="fs-5 text-uppercase text-primary">
              Trade Consultancy
            </p>
            <h1 className="display-3"> Explore Trade solutions</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-12 col-xl-12">
              <div className="row g-4">
                <div className="col-md-4">
                  <div className="team-item wow zoomIn" data-wow-delay="0.2s">
                    <img
                      src="img/Apparel & Lifestyle Products.jpg"
                      className="img-fluid w-100"
                      alt=""
                    />
                    <div className="team-content text-dark text-center py-3">
                      <div className="team-content-inner">
                        <h5 className="mb-0">Apparel & Lifestyle Products</h5>
                        <p className="text-dark">Trade Consultancy</p>
                        <div className="team-icon d-flex align-items-center justify-content-center">
                          <Link to="/Trade" className="btn btn-primary px-3">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="team-item wow zoomIn" data-wow-delay="0.2s">
                    <img
                      src="img/Cosmetics & Skincare.jpg"
                      className="img-fluid w-100"
                      alt=""
                    />
                    <div className="team-content text-dark text-center py-3">
                      <div className="team-content-inner">
                        <h5 className="mb-0">Cosmetics & Skincare</h5>
                        <p className="text-dark">Trade Consultancy</p>
                        <div className="team-icon d-flex align-items-center justify-content-center">
                          <Link to="/Trade" className="btn btn-primary px-3">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="team-item wow zoomIn" data-wow-delay="0.2s">
                    <img
                      src="img/Food & Beverages.jpg"
                      className="img-fluid w-100"
                      alt=""
                    />
                    <div className="team-content text-dark text-center py-3">
                      <div className="team-content-inner">
                        <h5 className="mb-0">Food & Beverages</h5>
                        <p className="text-dark">Trade Consultancy</p>
                        <div className="team-icon d-flex align-items-center justify-content-center">
                          <Link to="/Trade" className="btn btn-primary px-3">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="team-item wow zoomIn" data-wow-delay="0.2s">
                    <img
                      src="img/Pharma & HealthCare.jpg"
                      className="img-fluid w-100"
                      alt=""
                    />
                    <div className="team-content text-dark text-center py-3">
                      <div className="team-content-inner">
                        <h5 className="mb-0">Pharma & HealthCare</h5>
                        <p className="text-dark">Trade Consultancy</p>
                        <div className="team-icon d-flex align-items-center justify-content-center">
                          <Link to="/Trade" className="btn btn-primary px-3">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="team-item wow zoomIn" data-wow-delay="0.2s">
                    <img
                      src="img/Fitness & Sports.jpg"
                      className="img-fluid w-100"
                      alt=""
                    />
                    <div className="team-content text-dark text-center py-3">
                      <div className="team-content-inner">
                        <h5 className="mb-0">Fitness & Sports</h5>
                        <p className="text-dark">Trade Consultancy</p>
                        <div className="team-icon d-flex align-items-center justify-content-center">
                          <Link to="/Trade" className="btn btn-primary px-3">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="team-item wow zoomIn" data-wow-delay="0.2s">
                    <img
                      src="img/Retail, Fmcg & E-commerce.jpg"
                      className="img-fluid w-100"
                      alt=""
                    />
                    <div className="team-content text-dark text-center py-3">
                      <div className="team-content-inner">
                        <h5 className="mb-0">Retail, Fmcg & E-commerce</h5>
                        <p className="text-dark">Trade Consultancy</p>
                        <div className="team-icon d-flex align-items-center justify-content-center">
                          <Link to="/Trade" className="btn btn-primary px-3">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container text-center bg-primary py-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div className="row g-4 align-items-center">
            <div className="col-lg-2">
              <i className="fa fa-plane fa-5x text-white" />
            </div>
            <div className="col-lg-7 text-center text-lg-start">
              <h1 className="mb-0">
                Get Started Now: Fill out the getquote form and experience the{" "}
                {companyname} difference today.
              </h1>
            </div>
            <div className="col-lg-3">
              <Link to="/Getquote" className="btn btn-light py-2 px-4">
                Request For Quote
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
      <Footer />
    </>
  );
}
