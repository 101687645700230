import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Contact</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Contact
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}
      {/* Contact Start */}
      <div className="container-fluid contact py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "700px" }}
          >
            <p className="fs-5 text-uppercase text-primary">Get In Touch</p>
            <h1 className="display-3">Contact For Any Queries</h1>
            <p className="mb-0">
              Don't miss out on competitive rates and expert logistics support;
              submit the form now.
            </p>
          </div>
          <div className="row g-4 wow fadeIn" data-wow-delay="0.3s">
            <form
              id="contactForm"
              action="/php/thankyou-contact.php "
              method="post"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handleFnameChange(event)}
                      type="text"
                      id="fname"
                      name="fname"
                      value={fname}
                      placeholder="Enter your first name"
                      required
                    />
                    <label htmlFor="name">Enter your first name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handlelnameChange(event)}
                      type="text"
                      id="lname"
                      name="lname"
                      value={lname}
                      placeholder="Enter your last name"
                      required
                    />
                    <label htmlFor="email">Enter your last name</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handleemailChange(event)}
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email"
                      required
                    />
                    <label htmlFor="subject">Enter your Email</label>
                  </div>
                </div>{" "}
                <div className="col-12">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handlesubjectChange(event)}
                      type="text"
                      id="subject"
                      name="subject"
                      value={subject}
                      placeholder="Enter subject"
                      required
                    />
                    <label htmlFor="subject">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows={1}
                      placeholder="Enter Message"
                      defaultValue={""}
                      value={message}
                      onChange={(event) => handlemessageChange(event)}
                    />
                    <label htmlFor="message">Message</label>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary py-3 px-5"
                    type="submit"
                    defaultValue=""
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            <h5 style={{ color: "red" }}>{result}</h5>
          </div>
        </div>
      </div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.23164373565!2d72.95158527433667!3d19.185082248540713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9fbaf6defb3%3A0x8dcbb2b8b3a7dd4b!2sRatanbai%20Compound!5e0!3m2!1sen!2sin!4v1694425923521!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>{" "}
      <Footer />
    </>
  );
}
