import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Contract Logistics</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Contract Logistics
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1
            className="display-3 mb-5 wow fadeIn text-center"
            data-wow-delay="0.1s"
          >
            {" "}
            <span className="text-primary">Contract Logistics</span>
          </h1>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="blog-item ">
                <div className="blog-img position-relative overflow-hidden">
                  <img
                    src="img/Contract Logistics.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2"></div>

                  <p className="mb-4">
                    <p>
                      As a pioneering force in the field of contract logistics,{" "}
                      {companyname} stands apart due to its extensive
                      decade-long experience and profound expertise in the
                      industry. We offer a harmonious suite of integrated
                      services that cover the entire spectrum of supply chain
                      operations, guiding businesses towards streamlined and
                      efficient processes from start to finish. With a deep
                      understanding of industry intricacies, we engage in
                      collaborative partnerships with our clients, providing a
                      comprehensive range of solutions, including inventory
                      management, storage and distribution, route optimization,
                      order fulfillment, supply chain consultancy, and various
                      other services.
                    </p>

                    <p>
                      Our approach is centered on customization, tailoring our
                      services to precisely align with the unique needs of each
                      client. Whether it involves localized operations within a
                      specific geographical region or expansive projects
                      spanning multiple cities, our adaptability knows no
                      limits. Our strategy combines meticulous attention to
                      detail, a nationwide network, cutting-edge technology, and
                      strategic foresight, all carefully orchestrated to ensure
                      the successful execution of your business endeavors.
                    </p>

                    <p>
                      From the initial stages of our client engagement through
                      project execution and beyond, the foundation of our
                      philosophy rests on an unwavering commitment to service
                      excellence. When you choose {companyname}, you're not
                      merely accessing services; you're forming a partnership
                      with an organization that treats your success as its own.
                      Our commitment is enduring, representing a steadfast
                      pledge to ensure customer satisfaction, to drive your
                      business aspirations forward, and to serve as a reliable
                      pillar of support throughout your journey towards
                      operational excellence.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
