import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Air Freight</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Air Freight
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1
            className="display-3 mb-5 wow fadeIn text-center"
            data-wow-delay="0.1s"
          >
            {" "}
            <span className="text-primary">Air Freight</span>
          </h1>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="blog-item ">
                <div className="blog-img position-relative overflow-hidden">
                  <img
                    src="img/Air Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2"></div>

                  <p className="mb-4"> 
                    <p>
                      {companyname} is your trusted partner for delivering
                      comprehensive door-to-door air freight transportation
                      solutions for imports and exports. Our services encompass
                      various considerations, whether it's cost optimization or
                      expedited delivery. We manage both consolidated and direct
                      air services, finely tailored to meet your specific
                      requirements. With our skilled team at the helm, we ensure
                      the seamless transportation of your goods to and from
                      virtually any corner of the world.
                    </p>

                    <p>
                      In a world where supply chains have become critical
                      lifelines and swift transit times are imperative, we
                      leverage the expertise of premium carriers with
                      established routes for our consolidations. Our approach is
                      not limited to a single trajectory; instead, we offer a
                      wide spectrum of choices, ensuring that your air freight
                      forwarding needs are met with the utmost competitiveness
                      and reliability.
                    </p>

                    <p>
                      Beyond traditional services, we empower our clients to
                      leverage our robust international network of offices and
                      affiliates. This interconnected web enables us to
                      facilitate door-to-door deliveries, effortlessly
                      navigating the complexities of clearance procedures and
                      ensuring the secure delivery of your cargo to its final
                      destination. At {companyname}, our commitment is
                      unwavering – to provide you with a comprehensive and
                      hassle-free air freight experience, guaranteeing the
                      efficient, precise, and careful delivery of your cargo to
                      its destination.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
