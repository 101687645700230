import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Warehouse</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Warehouse
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1
            className="display-3 mb-5 wow fadeIn text-center"
            data-wow-delay="0.1s"
          >
            {" "}
            <span className="text-primary">Warehouse</span>
          </h1>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="blog-item ">
                <div className="blog-img position-relative overflow-hidden">
                  <img
                    src="img/Warehouse.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2"></div>

                  <p className="mb-4">
                    <p>
                      At {companyname}, our wealth of experience, finely 
                      cultivated over time, is dedicated to delivering top-tier
                      warehouse services that prioritize the safety and security
                      of your valuable products. Our comprehensive approach
                      covers every aspect of your supply chain management,
                      offering adept storage solutions and seamless distribution
                      strategies. As a pioneering presence among warehousing
                      companies in India, we take immense pride in our ability
                      to provide cost-effective, dependable, and resilient
                      warehouse logistics solutions, carefully tailored to meet
                      the diverse needs of every industry.
                    </p>

                    <p>
                      The foundation of our service portfolio is our commitment
                      to adaptability, a guiding principle that empowers us to
                      address a wide range of requirements. Whether you need
                      specialized space for extended durations or communal areas
                      for seasonal storage surges, we are ready to assist.
                      Backed by a nationwide network of warehouses, equipped
                      with state-of-the-art storage facilities, and supported by
                      robust technological systems, we offer a spectrum of
                      world-class warehouse services.
                    </p>

                    <p>
                      Our dedication goes beyond the physical realm; it embodies
                      trust and reliability. When you choose {companyname},
                      you're not just engaging with a service provider – you're
                      forming a partnership based on a promise, a commitment to
                      safeguarding your products and enhancing their journey. As
                      a beacon of excellence in the warehouse logistics domain,
                      we ensure that your goods are stored meticulously, managed
                      efficiently, and distributed seamlessly, creating an
                      environment of assurance and empowerment at every step of
                      your supply chain.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
