import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark"> About Us</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    About Us
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* About Satrt */}
            <div className="container-fluid about py-5">
        <div className="container py-5">
          <div className="row g-5 mb-5">
          <div className="col-xl-6">
              <div className="row g-4" style={{paddingTop:"100px"}}>
                <div className="col-6">
                  <img
                    src="img/Air Freight.jpg"
                    className="img-fluid h-100 wow zoomIn"
                    data-wow-delay="0.1s"
                    alt=""
                  />
                </div>
                <div className="col-6">
                  <img
                    src="img/About1.jpg"
                    className="img-fluid pb-3 wow zoomIn"
                    data-wow-delay="0.1s"
                    alt=""
                  />
                  <img
                    src="img/About2.jpg"
                    className="img-fluid pt-3 wow zoomIn"
                    data-wow-delay="0.1s"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 wow fadeIn" data-wow-delay="0.5s">
              <p className="fs-5 text-uppercase text-primary">
                About {companyname}
              </p>
              <h1 className="display-5 pb-4 m-0">
                Excellence in Every Shipment.
              </h1>
              <p>
                {companyname} has proudly held a prominent position as a
                provider of freight and trading services. Over time, we have
                embraced the challenges that our clients' intricate quandaries
                present, consistently delivering inventive and ingenious
                solutions. Our expertise, skilled workforce, cutting-edge
                technology, and the cumulative experience of our parent company
                come together to form an unbeatable alliance. Our origins are
                rooted in resolving complexities for our own entities, which is
                why we excel in expertly managing the burdens that may weigh
                upon you.
              </p>

              <p>
                Our approach extends beyond mere service provision; it is a
                dynamic partnership, a collaborative endeavor underpinned by
                clear and mutual understanding of your objectives. As we move
                forward, your success becomes intricately linked with ours.
              </p>

             
            </div>
            <p>Our diligence goes deeper than the surface; it's an immersive process that involves understanding your business, the intricacies of the challenges you face, and the outcomes that are of paramount significance to you. Our tenacity lies in decoding the nuances of your operations, in crafting solutions that align with your unique requirements, and in championing your objectives as our own. The tapestry we create isn't just about transactions; it's a symphony of shared aspirations, a testament to our commitment to stand firmly by your side, navigating the realm of business intricacies to deliver solutions that propel your success forward.</p>
          </div>
        </div>
      </div>
      {/* About End */}
            {/* Sermon Start */}
            <div className="container-fluid sermon py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "700px" }}
          >
            <p className="fs-5 text-uppercase text-primary">Services</p>
            <h1 className="display-3">Explore Our Services</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Road Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Road" className="d-inline-block h4 lh-sm mb-3">
                    Road Freight
                  </Link>
                  <p className="mb-0">
                    {companyname} takes immense pride in expanding our expertise
                    to include the critical domain of land freight trucking
                    services, in addition to our well-established air and ocean
                    freight offerings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Sea Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Sea" className="d-inline-block h4 lh-sm mb-3">
                    Sea Freight
                  </Link>
                  <p className="mb-0">
                    Whether you're dealing with a comprehensive full container
                    load (FCL) or a more streamlined less than container load
                    (LCL) shipment, you can count on us to align with your
                    overseas shipping schedules.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Air Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Air" className="d-inline-block h4 lh-sm mb-3">
                    Air Freight
                  </Link>
                  <p className="mb-0">
                    {companyname} is your trusted partner for delivering
                    comprehensive door-to-door air freight transportation
                    solutions for imports and exports. Our services encompass
                    various considerations,
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Warehouse.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link
                    to="/Warehouse"
                    className="d-inline-block h4 lh-sm mb-3"
                  >
                    Warehouse
                  </Link>
                  <p className="mb-0">
                    At {companyname}, our wealth of experience, finely
                    cultivated over time, is dedicated to delivering top-tier
                    warehouse services that prioritize the safety and security
                    of your valuable products.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Contract Logistics.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Contract" className="d-inline-block h4 lh-sm mb-3">
                    Contract Logistics
                  </Link>
                  <p className="mb-0">
                    As a pioneering force in the field of contract logistics,{" "}
                    {companyname} stands apart due to its extensive decade-long
                    experience and profound expertise in the industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="sermon-item wow fadeIn" data-wow-delay="0.1s">
                <div className="overflow-hidden p-4 pb-0">
                  <img
                    src="img/Third Party Logistics.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <Link to="/Third" className="d-inline-block h4 lh-sm mb-3">
                    Third Party Logistics
                  </Link>
                  <p className="mb-0">
                    {companyname} stands as a leading third-party logistics
                    (3PL) services provider, specializing in delivering
                    comprehensive and seamlessly integrated supply chain
                    solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Sermon End */}

      <Footer />
    </>
  );
}
