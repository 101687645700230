import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Trade Consultancy</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Trade Consultancy
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid event py-5">
        <div className="container py-5">
          <h1 className="display-3 mb-5 wow fadeIn" data-wow-delay="0.1s">
            Our <span className="text-primary">Trade Consultancy</span>
          </h1>
          <div className="row g-4 event-item wow fadeIn" data-wow-delay="0.1s">
            <div className="col-3 col-lg-2 pe-0">
              <div className="text-center border-bottom border-dark py-3 px-2"></div>
            </div>
            <div className="col-9 col-lg-6 border-start border-dark pb-5">
              <div className="ms-3">
                <h4 className="mb-3">Apparel & Lifestyle Products</h4>
                <p className="mb-4">
                  {companyname} is renowned for its excellence in providing
                  highly efficient warehouse and logistics services tailored
                  specifically for the apparel, fashion, uniforms, lifestyle
                  products, and the complex world of e-commerce and retail
                  distribution. Our services are recognized as industry
                  exemplars in the technology-driven landscape, delivering
                  solutions that embrace the digital frontier with cutting-edge
                  technology and a forward-thinking approach.
                </p>

                <p className="mb-4">
                  In the dynamic world of apparel and lifestyle, online sales
                  through various marketplaces take center stage. Recognizing
                  this, we prioritize streamlining the online retail experience
                  for brands, strategically positioning ourselves to meet the
                  anticipated surge in online retail growth in India in the
                  coming years.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="overflow-hidden mb-5">
                <img
                  src="img/Apparel & Lifestyle Products.jpg"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row g-4 event-item wow fadeIn" data-wow-delay="0.3s">
            <div className="col-3 col-lg-2 pe-0">
              <div className="text-center border-bottom border-dark py-3 px-2"></div>
            </div>
            <div className="col-9 col-lg-6 border-start border-dark pb-5">
              <div className="ms-3">
                <h4 className="mb-3">Cosmetics & Skincare</h4>
                <p className="mb-4">
                  {companyname} excels in meeting the unique requirements of
                  makeup and skincare companies by meticulously managing a
                  multifaceted logistics routine. We adeptly handle the
                  intricate demands of retailers and the ever-increasing demands
                  of e-commerce within this sector. Our approach goes beyond the
                  ordinary; it's a manifestation of foresight, combining
                  strategic strategies with unwavering precision. We are
                  committed to aligning with the dynamic cadence of the
                  cosmetics and skincare industry, consistently delivering
                  flawless planning and on-time deliveries.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="overflow-hidden mb-5">
                <img
                  src="img/Cosmetics & Skincare.jpg"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row g-4 event-item wow fadeIn" data-wow-delay="0.5s">
            <div className="col-3 col-lg-2 pe-0">
              <div className="text-center border-bottom border-dark py-3 px-2"></div>
            </div>
            <div className="col-9 col-lg-6 border-start border-dark pb-5">
              <div className="ms-3">
                <h4 className="mb-3">Food & Beverages</h4>
                <p className="mb-4">
                  {companyname} proudly presents its Contract Logistics model,
                  expertly designed to address the numerous challenges faced by
                  the Food and Beverage industry. This flexible framework serves
                  as a robust response to the complex issues in this thriving
                  sector, including fluctuating commodity prices and
                  manufacturing constraints. As consumer preferences shift
                  towards organic products, we lead the way in orchestrating a
                  logistics solution that seamlessly adapts to these evolving
                  trends.
                </p>

                <p className="mb-4">
                  Within this intricate ecosystem where hygiene, traceability,
                  and timeliness are paramount, our Contract Logistics model
                  provides unwavering support. We take each step meticulously,
                  leveraging our expertise to provide solutions that align with
                  the unique demands of the Food and Beverage industry.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="overflow-hidden mb-5">
                <img
                  src="img/Food & Beverages.jpg"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row g-4 event-item wow fadeIn" data-wow-delay="0.1s">
            <div className="col-3 col-lg-2 pe-0">
              <div className="text-center border-bottom border-dark py-3 px-2"></div>
            </div>
            <div className="col-9 col-lg-6 border-start border-dark pb-5">
              <div className="ms-3">
                <h4 className="mb-3">Pharma & HealthCare</h4>
                <p className="mb-4">
                  {companyname} takes immense pride in its Pharma & Healthcare
                  services portfolio, reflecting our deep commitment to the
                  well-being of individuals reliant on pharmaceutical products,
                  medicines, herbal remedies, and medical equipment. Our ethos
                  revolves around a profound sense of responsibility,
                  recognizing the critical role these products play in
                  safeguarding lives.
                </p>

                <p className="mb-4">
                  In this realm, our commitment is evident through a
                  comprehensive set of measures designed to uphold sanitation,
                  safety, and stringent quality standards. Our
                  temperature-controlled warehouses ensure a stable environment
                  crucial for preserving the efficacy of sensitive healthcare
                  items. We relentlessly strive to prevent shortages, ensuring
                  that critical supplies remain consistently available.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="overflow-hidden mb-5">
                <img
                  src="img/Pharma & HealthCare.jpg"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row g-4 event-item wow fadeIn" data-wow-delay="0.3s">
            <div className="col-3 col-lg-2 pe-0">
              <div className="text-center border-bottom border-dark py-3 px-2"></div>
            </div>
            <div className="col-9 col-lg-6 border-start border-dark pb-5">
              <div className="ms-3">
                <h4 className="mb-3">Fitness & Sports</h4>
                <p className="mb-4">
                  {companyname} proudly holds its position as a premier freight
                  forwarder, facilitating the import and export of goods to and
                  from India seamlessly. Our strength lies in an extensive
                  network of warehouses and distribution hubs strategically
                  placed across India, complemented by a comprehensive range of
                  international air freight, ocean cargo transportation, and
                  on-carriage trucking services.
                </p>

                <p className="mb-4">
                  Our approach is based on synergy, harmoniously blending
                  domestic freight services, Less-Than-Truckload (LTL) freight
                  solutions, and domestic air cargo operations. This
                  orchestration ensures a seamless flow as goods travel from
                  warehouses to airports, docks, consolidation hubs, or dispatch
                  centers throughout India.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="overflow-hidden mb-5">
                <img
                  src="img/Fitness & Sports.jpg"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row g-4 event-item wow fadeIn" data-wow-delay="0.5s">
            <div className="col-3 col-lg-2 pe-0">
              <div className="text-center border-bottom border-dark py-3 px-2"></div>
            </div>
            <div className="col-9 col-lg-6 border-start border-dark pb-5">
              <div className="ms-3">
                <h4 className="mb-3">Retail, Fmcg & E-commerce</h4>
                <p className="mb-4">
                  In response to the ever-changing needs of the retail and
                  e-commerce sectors, {companyname} offers a comprehensive suite
                  of multimodal transport services. Designed to meet the
                  evolving demands of the modern landscape, our services address
                  requirements that span from swift one-day deliveries to
                  express consignments.
                </p>

                <p className="mb-4">
                  s As a testament to our dedication to seamless logistics, we
                  leverage our expertise to cover a wide range of Fast-Moving
                  Consumer Goods (FMCG) logistics solutions. Supported by a vast
                  network of skilled professionals, state-of-the-art decision
                  support systems, and in-house technological capabilities, we
                  are well-prepared to craft solutions that not only meet the
                  intricate demands of FMCG logistics but also elevate them to
                  new levels of efficiency.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="overflow-hidden mb-5">
                <img
                  src="img/Retail, Fmcg & E-commerce.jpg"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
