import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <div
        className="container-fluid footer pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-7">
              <h1 className="text-light mb-0">Subscribe our newsletter</h1>
             
            </div>
            <div className="col-lg-5">
              <div className="position-relative mx-auto">
              <form
                  className=""
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <input
                    onChange={(event) => handleSubemailChange(event)}
                    id="subemail"
                    value={subemail}
                    type="email"
                    name="subemail"
                    placeholder="Your email address"
                    required
                    className="form-control border-0 w-100 py-3 ps-4 pe-5"
                  />
                  <button
                    className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                    type="submit"
                    name="submit"
                  >
                    Subscribe Now
                  </button>
                </form>
                <h5>{result}</h5>
              </div>
            </div>
            <div className="col-12">
              <div className="border-top border-secondary" />
            </div>
          </div>
          <div className="row g-4 footer-inner">
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item mt-5">
                <h4 className="text-light mb-4">
                  THE<span className="text-primary">Mosque</span>
                </h4>
                <p className="mb-4 text-secondary">
                Beyonics International has proudly held a prominent position as a provider of freight and trading services. Over time, we have embraced the challenges that our clients' intricate quandaries present, consistently delivering inventive and ingenious solutions. 
                </p>
               
              </div>
            </div>

            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item mt-5">
                <h4 className="text-light mb-4">Important Links</h4>
                <div className="d-flex flex-column align-items-start">
                  <Link className="text-body mb-2" to="/Home">
                    <i className="fa fa-check text-primary me-2" />
                    Home
                  </Link>
                  <Link className="text-body mb-2" to="/About">
                    <i className="fa fa-check text-primary me-2" />
                    About Us
                  </Link>
                 
                  <Link className="text-body mb-2" to="/Trade">
                    <i className="fa fa-check text-primary me-2" />
                    Trade Consultancy
                  </Link>
                  <Link className="text-body mb-2" to="/Contact">
                    <i className="fa fa-check text-primary me-2" />
                    Contact Us
                  </Link>
                  <Link className="text-body mb-2" to="/Getquote">
                    <i className="fa fa-check text-primary me-2" />
                    Request For Quote
                  </Link>
                  <Link className="text-body mb-2" to="/Privacy">
                    <i className="fa fa-check text-primary me-2" />
                    Privacy Policy
                  </Link>
                  <Link className="text-body mb-2" to="/Terms">
                    <i className="fa fa-check text-primary me-2" />
                    Terms & Conditions
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item mt-5">
                <h4 className="text-light mb-4">Services</h4>
                <div className="d-flex flex-column align-items-start">
                  <Link to="/Road" className="text-body mb-2"> <i className="fa fa-check text-primary me-2" />
                    Road Freight
                  </Link>
                  <Link to="/Sea" className="text-body mb-2"> <i className="fa fa-check text-primary me-2" />
                    Sea Freight
                  </Link>
                  <Link to="/Air" className="text-body mb-2"> <i className="fa fa-check text-primary me-2" />
                    Air Freight
                  </Link>
                  <Link to="/Warehouse" className="text-body mb-2"> <i className="fa fa-check text-primary me-2" />
                    Warehouse
                  </Link>
                  <Link to="/Contract" className="text-body mb-2"> <i className="fa fa-check text-primary me-2" />
                    Contract Logistics
                  </Link>
                  <Link to="/Third" className="text-body mb-2"> <i className="fa fa-check text-primary me-2" />
                    Third Party Logistics
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item mt-5">
                <h4 className="text-light mb-4">Get In Touch</h4>
                <div className="d-flex flex-column">
                  <h6 className="text-secondary mb-0">Our Address</h6>
                  <div className="d-flex align-items-center border-bottom py-4">
                    <span className="flex-shrink-0 btn-square bg-primary me-3 p-4">
                      <i className="fa fa-map-marker-alt text-dark" />
                    </span>
                    <Link to className="text-body">
                      {companyaddress}
                    </Link>
                  </div>
                  <h6 className="text-secondary mt-4 mb-0">Phone</h6>
                  <div className="d-flex align-items-center py-4">
                    <span className="flex-shrink-0 btn-square bg-primary me-3 p-4">
                      <i className="fa fa-phone-alt text-dark" />
                    </span>
                    <Link to className="text-body">
                     {companynumber}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <div className="border-top border-secondary pb-4" />
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©2023{" "}
              <Link className="border-bottom" to="/">
                {companyname}
              </Link>
              , All Right Reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
