import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Sea Freight</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Sea Freight
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1
            className="display-3 mb-5 wow fadeIn text-center"
            data-wow-delay="0.1s"
          >
            {" "}
            <span className="text-primary">Sea Freight</span>
          </h1>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="blog-item ">
                <div className="blog-img position-relative overflow-hidden">
                  <img
                    src="img/Sea Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2"></div>

                  <p className="mb-4">
                    <p>
                      Whether you're dealing with a comprehensive full container
                      load (FCL) or a more streamlined less than container load
                      (LCL) shipment, you can count on us to align with your
                      overseas shipping schedules. We operate numerous sailings
                      every week, ensuring timely service.
                    </p>
 
                    <p>
                      Our ocean freight division, specializing in this field,
                      coordinates the intricate dance of logistical maneuvers,
                      ensuring the smooth transit of full container load (FCL)
                      cargo to and from international ports worldwide. We offer
                      tailored solutions for both FCL and LCL shipments,
                      facilitating seamless exchanges between major ports across
                      the globe.
                    </p>

                    <p>
                      Our capabilities extend beyond mere cargo transportation.
                      Whether you have a single shipment, a complex series of
                      shipments, or intricate project cargo, our skilled team is
                      ready to meet all your shipping requirements. With a
                      combination of expertise, industry knowledge, and an
                      unwavering commitment to customer satisfaction, we
                      orchestrate a symphony of services tailored to your unique
                      demands. With each shipment, our promise remains unchanged
                      – to exceed your expectations, guiding you through the
                      complex world of logistics with the ease of sailing on
                      calm waters.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
