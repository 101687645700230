import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
   {/* Topbar start */}
   <div className="container-fluid fixed-top">
        <div className="container topbar d-none d-lg-block">
          <div className="topbar-inner">
            <div className="row gx-0">
              <div className="col-lg-7 text-start">
                <div className="h-100 d-inline-flex align-items-center me-4">
                  <span className="fa fa-phone-alt me-2 text-dark" />
                  <Link to="#" className="text-secondary"><span>{companynumber}</span></Link>
                </div>
                <div className="h-100 d-inline-flex align-items-center">
                  <span className="far fa-envelope me-2 text-dark" />
                  <Link to="#" className="text-secondary"><span>{companyemail}</span></Link>
                </div>
              </div>
              <div className="col-lg-5 text-end">
                <div className="h-100 d-inline-flex align-items-center">
                  <Link className="text-body ps-4" to="/Privacy"> Privacy Policy </Link>
                  <Link className="text-body ps-4" to="/Terms"> Terms & Conditions</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <nav className="navbar navbar-light navbar-expand-lg py-3" style={{background:"white"}}>
            <Link to="/Home" className="navbar-brand">
              <img src="img/logo.png" style={{height:"80px"}}></img>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="fa fa-bars text-primary" />
            </button>
            <div className="collapse navbar-collapse bg-white" id="navbarCollapse">
              <div className="navbar-nav ms-lg-auto mx-xl-auto">
                <Link to="/Home" className="nav-item nav-link ">Home</Link>
                <Link to="/About" className="nav-item nav-link">Company Profile</Link>
                
                <div className="nav-item dropdown">
                  <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">What We Do</Link>
                  <div className="dropdown-menu m-0 rounded-0">
                    <Link to="/Road" className="dropdown-item">Road Freight</Link>
                    <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                    <Link to="/Air" className="dropdown-item">Air Freight</Link>
                    <Link to="/Warehouse" className="dropdown-item">Warehouse</Link>
                    <Link to="/Contract" className="dropdown-item">Contract Logistics</Link>
                    <Link to="/Third" className="dropdown-item">Third Party Logistics</Link>
                  </div>
                </div>
                <div className="nav-item dropdown">
                  <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Trade Consultancy</Link>
                  <div className="dropdown-menu m-0 rounded-0">
                    <Link to="/Trade" className="dropdown-item">Food & Beverages</Link>
                    <Link to="/Trade" className="dropdown-item">Retail, Fmcg & E-commerce</Link>
                    <Link to="/Trade" className="dropdown-item">Pharma & HealthCare</Link>
                    <Link to="/Trade" className="dropdown-item">Apparel & Lifestyle Products</Link>
                    <Link to="/Trade" className="dropdown-item">Cosmetics & Skincare</Link>
                    <Link to="/Trade" className="dropdown-item">Fitness & Sports</Link>                  
                  </div>
                </div>
                <Link to="/Contact" className="nav-item nav-link">Contact Us</Link>
              </div>
              <Link to="/Getquote" className="btn btn-primary py-2 px-4 d-xl-inline-block">Requst For Quote</Link>
            </div>
          </nav>
        </div>
      </div>
      {/* Topbar End */}
    </>
  );
};

export default Header;
