import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Third Party Logistics</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Third Party Logistics
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1
            className="display-3 mb-5 wow fadeIn text-center"
            data-wow-delay="0.1s"
          >
            {" "}
            <span className="text-primary">Third Party Logistics</span>
          </h1>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="blog-item ">
                <div className="blog-img position-relative overflow-hidden">
                  <img
                    src="img/Third Party Logistics.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2"></div>

                  <p className="mb-4">
                    <p>
                      {companyname} stands as a leading third-party logistics
                      (3PL) services provider, specializing in delivering
                      comprehensive and seamlessly integrated supply chain
                      solutions. In today's dynamic and highly competitive
                      business landscape, the demand for 3PL services has become
                      unmistakably evident. Modern businesses recognize the
                      inherent value of entrusting their supply chain needs to
                      seasoned experts, ultimately enhancing customer
                      satisfaction and boosting profitability. In response to
                      this shift in perspective, {companyname} steps forward to
                      provide innovative and strategic logistics solutions to a
                      diverse range of industry sectors.
                    </p>

                    <p>
                      Our commitment is reflected in our comprehensive suite of
                      3PL services, thoughtfully designed to encompass the
                       entire spectrum of supply chain requirements. From
                      precision-driven inventory management and proficient
                      storage solutions to seamless transportation coordination
                      and flawless order fulfillment, we offer an array of
                      solutions that not only streamline operations but also
                      enhance efficiency and reduce costs. Our services are
                      founded on principles of cost-effectiveness, customized
                      solutions, and an unwavering focus on placing the customer
                      at the center of our efforts.
                    </p>

                    <p>
                      The strength of our extensive network, spanning both
                      transportation and warehousing, empowers businesses to
                      expand their horizons, confidently tapping into new
                      markets and opportunities. Supported by a technological
                      infrastructure fortified with cutting-edge innovations, we
                      ensure end-to-end visibility and transparency throughout
                      every aspect of your supply chain operations. At{" "}
                      {companyname}, our guiding principles are a relentless
                      pursuit of excellence and an unceasing commitment to
                      innovation, characteristics that define our dedication to
                      elevating your operations to the pinnacle of success.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
